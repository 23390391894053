import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Redirect, Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ForwardIcon from '@material-ui/icons/Forward';

import IntegrationReactSelect from './IntegrationReactSelect';
import ContentsContext from './ContentsContext';
import SettingContext from './SettingContext';
import SettingManager from "../SettingManager";
import PdfViewer from "./pdfviewer/PdfViewer";
import { isMobile } from "react-device-detect";
/*import VerseComponent from "./VerseComponent";*/
import { books } from '../config';
import copyText from '../modules/copy-to-clipboard';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const styles = theme => ({
  root: {
    padding: theme.spacing(1),
    marginLeft: 0, marginRight: 0,
    width: '100%',
  },
  cardtop: {
    marginBottom: 0,
    boxShadow: 'unset'
  },
  card: {
    marginTop: '-30px',
    marginBottom: 0,
    boxShadow: 'unset'
  },
  content: {
    display: "inline-flex",
    padding: '16px 16px 0 !important',
    marginBottom: '-40px'
  },
  bookLinks: {
    fontFamily: 'Noto Sans TC, sans-serif',
    color: '#1d19c8',
    textDecoration: 'underline'
  },
  notes: {
    display: "block",
    fontFamily: 'Noto Sans TC, sans-serif',
    padding: '16px 16px 0 !important',
    marginLeft: '18px',
    marginBottom: '-15px'
  },
  headerNotes: {
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '-27px',
    color: '#884747'
  },
  noteRight: {
    marginLeft: '50px'
  },
  noteRightMobile: {
    marginLeft: '4px'
  },
  noteRightMedium: {
    marginLeft: '53px'
  },
  noteRightMediumMobile: {
    marginLeft: '7px'
  },
  noteRightMediumMore: {
    marginLeft: '60px'
  },
  noteRightMediumMoreMobile: {
    marginLeft: '10px'
  },
  noteRightMore: {
    marginLeft: '62px'
  },
  noteRightMoreMobile: {
    marginLeft: '16px'
  },
  noteRightMost: {
    marginLeft: '75px'
  },
  noteRightMostMobile: {
    marginLeft: '25px'
  },
  noteRightLetter: {
    marginLeft: '16px'
  },
  noteRightLetterMobile: {
    marginLeft: '11px'
  },
  vt10: {
    marginTop: '10px',
    marginBottom: '10px'
  },
  vt0: {
    marginTop: 0,
    marginBottom: 0
  },
  vta10: {
    marginTop: '10px',
    marginBottom: 0
  },
  vtb10: {
    marginTop: 0,
    marginBottom: '10px'
  },
  typographyParagraph1: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '43px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyMobileParagraph1: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'7px'*/'-1px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyParagraph2: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '32px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyMobileParagraph2: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'-1px'*/'-9px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyParagraph3: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '38px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyMobileParagraph3: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'-9px'*/'-17px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyRangeMobileParagraph: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'-6px'*/'-13px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographySplitParagraph: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '46px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographySplitParagraphMobile: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'13px'*/'4px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographySplit2Paragraph: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '69px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographySplit2ParagraphMobile: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'37px'*/'29px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyCombinedParagraph: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '32px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyCombinedParagraphMobile: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'26px'*/ '18px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyCombined2Paragraph: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '57px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyCombined2ParagraphMobile: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'19px'*/'11px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyCombined3ParagraphMobile: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: /*'35px'*/'27px',
    whiteSpace: 'nowrap',
    lineHeight: 1.65
  },
  typographyNotes1: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '54px',
    whiteSpace: 'nowrap',
    color: '#884747'
  },
  typographyMobileNotes1: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '-27px',
    whiteSpace: 'nowrap',
    color: '#884747'
  },
  typographyNotes2: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '60px',
    whiteSpace: 'nowrap',
    color: '#884747'
  },
  typographyMobileNotes2: {
    display: "inline-block",
    fontFamily: 'Noto Sans TC, sans-serif',
    marginLeft: '-25px',
    whiteSpace: 'nowrap',
    color: '#884747'
  },
  verse: {
    marginLeft: '20px'
  },
  verseRange: {
    marginLeft: '-20px'
  },
  verseRangeShort: {
    marginLeft: '2px'
  },
  verseLetter: {
    marginLeft: '-2px'
  },
  verseLetterShort: {
    marginLeft: '7px'
  },
  copyButton: {
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing(1),
    position: 'fixed',
    bottom: 6,
    right: 6,
  },
  buttonIcon: {
    fontSize: 30,
  },
});

const override_verse_labels = {
  //book => [chapter, verse, label, nextverse]
  'gn': [[4, 4, '4a|4b', 5],[4, 5, '5a|5b', 6],[24, 29, '29,30', 31]],
  'ex': [[38, 14, '14,15', 16]],
  'lv': [[3, 14, '14,15', 16]],
  'nm': [[1, 20, '20,21', 22],[1, 22, '22,23', 24],[1, 24, '24,25', 26],[1, 26, '26,27', 28],[1, 28, '28,29', 30],[1, 30, '30,31', 32],[1, 32, '32,33', 34],[1, 34, '34,35', 36],[1, 36, '36,37', 38],[1, 38, '38,39', 40],[1, 40, '40,41', 42],[1, 42, '42,43', 44],[1, 45, '45,46', 47],[4, 39, '39,40', 41],[4, 43, '43,44', 45],[4, 47, '47,48', 49],[12, 2, '2a|2b', 3],[14, 10, '10a|10b', 11]],
  'dt': [[4, 32, '32,33', 34],[13, 12, '12,13', 14],[13, 17, '17,18', 19],[15, 4, '4,5', 6],[28, 13, '13,14', 15],[28, 58, '58,59', 60],[29, 10, '10,11', 12],[30, 9, '9,10', 11]],
  'js': [[3, 10, '10,11', 12],[15, 5, '5a|5b', 6],[21, 9 , '9,10', 11]],
  'jud': [[20, 27, '27,28', 29]],
  '1sm': [[25, 30, '30,31', 32]],
  '2sm': [[3, 9 , '9,10', 11]],
  '1ch': [[1, 4, '4a|4b', 5],[4, 17, '17,18', 19],[16, 12, '12,13', 14],[16, 39, '39,40', 41]],
  '2ch': [[30, 18, '18,19', 20]],
  'ne': [[1, 4, '4a|4b', 5],[11, 3, '3a|3b', 4],[12, 28, '28,29', 30]],
  'et': [[1, 13, '13,14', 15],[6, 6, '6a|6b', 7],[8, 11, '11,12', 13]],
  'job': [[10, 20, '20,21', 22],[38, 37, '37,38', 39],[38, 39, '39,40', 41]],
  'ps': [[8, 6, '6-8', 9],[10, 5, '5a|5b', 6],[40, 9, '9a|9b', 10],[43, 4, '4a|4b', 5],[49, 8, '8,9', 10],[63, 5, '5,6', 7],[76, 8, '8,9', 10],[88, 9, '9a|9b', 10],[92, 2, '2,3', 4],[105, 5, '5,6', 7],[116, 18, '18,19', 20],[125, 5, '5a|5b', 6],[135, 1, '1,2', 3]],
  'prv': [[23, 31, '31,32', 33],[26, 18, '18,19', 20]],
  'ec': [[7, 27, '27,28', 29]],
  'so': [[1, 4, '4a|4b', 5],[5, 1, '1a|1b', 2], [6, 13, '13a|13b', 14], [7, 9, '9a|9b', 10], [8, 5, '5a|5b', 6]],
  'is': [[2, 2, '2a|2b', 3],[2, 4, '4a|4b', 5],[4, 3, '3,4', 5],[58, 3, '3a|3b', 4],[65, 6, '6,7', 8]],
  'jr': [[34, 8, '8,9', 10],[34, 18, '18,19', 20],[37, 17, '17a|17b', 18],[43, 5, '5,6', 7]],
  'ez': [[16, 56, '56,57', 58], [18, 10, '10,11', 12],[39, 26, '26,27', 28]],
  'dn': [[11, 30, '30a|30b', 31]],
  'ho': [[2, 2, '2a|2b', 3], [12, 4, '4,5', 6]],
  'jl': [[3, 2, '2a|2b', 3]],
  'hg': [[1, 1, '1a|1b', 2]],
  'mt': [[10, 8, '8a|8b', 9],[13, 57, '57a|57b', 58]],
  'lk': [[2, 34, '34,35', 36]],
  'act': [[1, 24, '24,25', 26],[4, 29, '29,30', 31],[9, 19, '19a|19b', 20],[14, 20, '20a|20b', 21]],
  'rm': [[11, 16, '16a|16b', 17]],
  'eph': [[6, 2, '2,3', 4]],
  'cl': [[2, 20, '20,21', 22]],
  'jm': [[5, 3, '3a|3b', 4], [5, 11, '11a|11b', 12], [5, 16, '16a|16b', 17]],
  '2pe': [[2, 13, '13a|13b', 14]],
  '3jo': [[1, 6, '6a|6b', 7], [1, 10, '10a|10b', 11]],
  're': [[4, 3, '3a|3b', 4], [4, 5, '5a|5b', 6], [4, 6, '6a|6b', 7]]
};

const valueToObj = books.reduce((acc, cur) => { acc[cur.key] = cur; return acc }, {});

class Bible extends Component {
  static contextType = ContentsContext;

  state = {
    snackbarOpen: false
  };

  onBookChange = val => {
    const {activated} = this.props.appState;
    if (val.value !== undefined && activated)
      this.props.history.push(`/${val.value}`);
  };

  onChapterChange = val => {
    const {activated} = this.props.appState;
    if (val.value !== undefined && activated)
      this.props.history.push(`/${this.props.book}/${val.value}`);
  };

  handleCopyText = txt => () => {
    copyText(txt);
    this.setState({ snackbarOpen: true });
  };

  handleClose = (evt, reason) => {
    if (reason === 'clickaway')
      return;
    this.setState({ snackbarOpen: false });
  };

  handleClick = () => {
    window.scrollTo({
      top: 0,
      // behavior: "smooth"
    });
  };

  componentDidMount() {
    if (this.initVerse){
      const topBlank = window.innerWidth < 600 ? 62 : 70;
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.initVerse).offsetTop - topBlank,
        // behavior: "smooth"
      });
    } else {

      window.scrollTo(0, 0);

    }
  }

  generateVerses = (verses) => {
    const { classes, contents } = this.props;
    let nextversenum = null;
    let nextverseletter = null;

    //@TODO find a way to get this from config file
    const langcode = 'cn_cob';

    return (
      verses.map((e, i) => {
        const bookabbrv = (window.location.href.split('/'))[3];
        const versenum = nextversenum !== null ? nextversenum : i + 1;
        let notesverse = versenum;
        if (nextversenum !== null) {
          nextversenum = nextversenum + 1;
        }
        const verseHighlight = typeof this.props.verse !== 'undefined' && versenum === Number(this.props.verse);
        const pnotesclass = i <= 8 ? (isMobile ? classes.typographyMobileNotes1 : classes.typographyNotes1) : (isMobile ? classes.typographyMobileNotes2 : classes.typographyNotes2);
        const cardclass = i >= 1 ? classes.card : classes.cardtop;
        const cardkey = i;
        let verse_label = this.props.chapter + ':' + versenum;
        let override = false;

        if (typeof override_verse_labels[bookabbrv] !== 'undefined') {
          override_verse_labels[bookabbrv].map((vop) => {
            if (vop[0].toString() === this.props.chapter.toString() && vop[1] === versenum) {
              if (vop[2].indexOf('|') !== -1) {
                if (nextverseletter === 'b') {
                  verse_label = this.props.chapter + ':' + versenum + 'b';
                  nextversenum = vop[3];
                  nextverseletter = null;
                } else {
                  verse_label = this.props.chapter + ':' + versenum + 'a';
                  nextversenum = versenum;
                  nextverseletter = 'b';
                }
                override = true;
              } else {
                verse_label = this.props.chapter + ':' + vop[2];
                nextversenum = vop[3];
                override = true;
              }
            }
            return null;
          });
        }
        let ptypoclass;
        if (verse_label.indexOf('-') !== -1 && isMobile) {
          ptypoclass = classes.typographyRangeMobileParagraph;
        } else if (verse_label.indexOf('a') !== -1 || verse_label.indexOf('b') !== -1) {
          if (isMobile) {
            ptypoclass = verse_label.length > 5 ? classes.typographySplit2ParagraphMobile : classes.typographySplitParagraphMobile;
          } else {
            ptypoclass = verse_label.length > 5 ? classes.typographySplit2Paragraph : classes.typographySplitParagraph;
          }
        } else if (verse_label.indexOf(',') !== -1) {
          if (isMobile) {
            ptypoclass = verse_label.length <= 6 ? classes.typographyCombined2ParagraphMobile : (verse_label.length === 7 ? classes.typographyCombined3ParagraphMobile : classes.typographyCombinedParagraphMobile);
          } else {
            ptypoclass = verse_label.length <= 6 ? classes.typographyCombined2Paragraph : classes.typographyCombinedParagraph;
          }
        } else if (verse_label.length >= 5) {
          if (isMobile) {
            ptypoclass = classes.typographyMobileParagraph3;
          } else {
            ptypoclass = classes.typographyParagraph3;
          }
        } else if (verse_label.length > 3 || override) {
          if (isMobile) {
            ptypoclass = classes.typographyMobileParagraph2;
          } else {
            ptypoclass = classes.typographyParagraph2;
          }
        } else if (verse_label.length <= 3) {
          if (isMobile) {
              ptypoclass = classes.typographyMobileParagraph1;
          } else {
              ptypoclass = classes.typographyParagraph1;
          }
        }
        //const ptypoclass = verse_label.length <= 3|| override ? (isMobile ? classes.typographyMobileParagraph1 : classes.typographyParagraph1) : (isMobile ? classes.typographyMobileParagraph2 : classes.typographyParagraph2);

        if (override) {
          const startlabel = verse_label.indexOf(':') + 1;
          notesverse = verse_label.substring(startlabel);
        }

        const notesarr = SettingManager.showOutline() ? this.getNotesForVerse(notesverse) : [];

        const versearr = verse_label.split(':');
        const verseRangeClass = /*versearr[1].length > 3*/verse_label.length > 5 ? classes.verseRange : (/*versearr[1].length === 2*/ verse_label.length <= 4 && (versearr[1].indexOf('a') !== -1 || versearr[1].indexOf('b') !== -1)) ? classes.verseLetterShort : (verse_label.length > 4 /*versearr[1].length === 3*/ && (versearr[1].indexOf('a') !== -1 || versearr[1].indexOf('b') !== -1)) ? classes.verseLetter : classes.verseRangeShort;
        const verseClass = override ? verseRangeClass : classes.verse;
        const shownotes = notesarr.length > 0 && SettingManager.showOutline();
        const contentClass = verseHighlight && !classes.content.includes('verse-highlight') ? classes.content += ' verse-highlight' : classes.content.replace(' verse-highlight', '');
        const {textsize} = this.props;
        const fontsize = textsize+'px';

        return (
            <Card key={cardkey} className={cardclass}
                  ref={ref => cardkey + 1 === this.props.verse && (this.initVerse = ref)}>
              {i === 0 &&
                <CardContent className={classes.bookLinks}>
                  <Typography gutterBottom variant="subtitle1" component="p">
                    <a href={'/' + bookabbrv + '/outline'}>大綱</a>   |   <a href={'/' + bookabbrv + '/chart'}>橫圖</a>
                  </Typography>
                </CardContent>
              }
              {shownotes && notesarr.map((noteobj, i) => (
                  <CardContent key={i} className={classes.notes}>
                    {noteobj.level === 'L01' || noteobj.level === 'L02' || noteobj.level === 'L03' || noteobj.level === '00H' || noteobj.level === '02H' ?
                        <Typography gutterBottom variant="subtitle1" component="p" className={this.getNoteStyle(noteobj, classes.headerNotes)}>
                          <span key={i} style={{fontSize: fontsize}}>{this.formatNote(noteobj)}</span>
                        </Typography>
                        :
                        <Typography key={langcode} component="p" className={this.getNoteStyle(noteobj, pnotesclass)}
                                    variant="subtitle1">
                          <span key={i} style={{fontSize: fontsize}}>{this.formatSubNote(noteobj)}</span>
                        </Typography>
                    }
                  </CardContent>
              ))
              }
              <CardContent className={contentClass}>
                <Typography gutterBottom variant="h6" component="h5" style={{marginLeft: '-12px'}}>
                  {verse_label.indexOf(',') !== -1 ?
                      <Fragment>
                        <span
                            onClick={() => this.props.history.replace(`/${this.props.book}/${this.props.chapter}/${cardkey + 1}`)}
                            style={{cursor: 'pointer', fontWeight: 400, fontSize: fontsize, marginTop: '-5px'}}>
                        {/*{ko_abbr}*/}{verse_label.split(',')[0]+','}
                        </span>
                        <p style={{marginTop: '-10px'}}><span style={{cursor: 'pointer', fontWeight: 400, fontSize: '16px', marginLeft: verse_label.length > 8 ? '32px' : (verse_label.length > 7 ? '22px' : '13px')}}>{verse_label.split(',')[1]}</span></p>
                      </Fragment>
                  :
                      <span
                          onClick={() => this.props.history.replace(`/${this.props.book}/${this.props.chapter}/${cardkey + 1}`)}
                          style={{cursor: 'pointer', fontWeight: 400, fontSize: fontsize, marginTop: '-5px'}}>
                      {/*{ko_abbr}*/}{verse_label}
                      </span>
                  }
                </Typography>
                <SettingContext.Consumer>
                  {({ visibleLanguages }) => (
                      visibleLanguages.map((lang, index) => {
                        const isLastElement = i === verses.length - 1;
                        const verseClassName = isLastElement ? `${verseClass} last` : verseClass;
                        return (
                            e[lang.code] && (
                                <Fragment key={lang.code}>
                                  <Typography component="p" className={ptypoclass} variant="subtitle1">
                                    {/*<strong>({lang.label})</strong>&nbsp;*/}
                                    <span style={{ fontSize: fontsize }}>{this.parseNewlines(e[lang.code], verseClassName)}</span>&nbsp;
                                  </Typography>
                                  {/*<span className={classes.copyButton} onClick={this.handleCopyText(`(${ko_abbr}${this.props.chapter}:${i + 1}) ${e[lang.code]}`)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path
              d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>
          </span>*/}
                                </Fragment>
                            )
                        );
                      })
                  )}
                </SettingContext.Consumer>
              </CardContent>
            </Card>
        )
      }));
  };

  parseNewlines = (text, verseClass) => {
    const textLastKey = text.split('\n').length - 1;
    return text.split('\n').map(function(item, key) {
  /*    const itemarr = item.split('<!u>');

      const parseVerseUnderlines = (item, itemarr) => {
        let searchKeyword = '<u>';

        let uIndices = [], uTermIndices = [];

        var indexOccurence = item.indexOf(searchKeyword, 0);

        while(indexOccurence >= 0) {
          uIndices.push(indexOccurence);

          indexOccurence = item.indexOf(searchKeyword, indexOccurence + 1);
        }

        searchKeyword = '</u>';


        let verseHtml = '';
        if (itemarr.length) {
          itemarr.forEach((substr, index) => {
            const u_pos = substr.indexOf('<u>');
            const u_term = substr.indexOf('</u>');
            console.log(u_pos, u_term);

            if (u_pos > 0) {
              verseHtml += '<span>'+item.substring(0, u_pos - 1)+'</span>'
            }
            if (substr.includes('<u>') && substr.includes('</u>')) {
              verseHtml += '<span style="text-decoration: underline;">'+item.substring(u_pos + 3, u_term - 1)+'</span>';
            } else {
              if (substr.length) {  verseHtml += '<span>'+substr+'</span>'; }
            }
          });
        } else {
          if (item.length) { verseHtml += '<span>'+item+'</span>'; }
        }
        console.log(verseHtml);
        return verseHtml;
      };*/

      return (
          <Fragment key={key}>
            <span className={verseClass} dangerouslySetInnerHTML={{__html: item}}/>
            {verseClass.includes('last') && key === textLastKey ?
                <Fragment><br/><br/></Fragment> :
              <br/>
            }
          </Fragment>
      )
    })
  }
  formatNote = (noteobj) => {
    const { classes } = this.props;

    const notenumend = noteobj.text.substring(0, 4).indexOf('.') + 1;
    const notenum = noteobj.text.substring(0, notenumend).indexOf('.') !== -1  ? noteobj.text.substring(0, notenumend).replace(' ', '') : '';
    let noteClass;

    if (noteobj.level === 'L01' || noteobj.level === 'L02' || noteobj.level === 'L03' || noteobj.level === '00L' || noteobj.level === '02L') {
       if (notenum.indexOf('一') !== -1 || notenum.indexOf('二.') !== -1 || notenum.indexOf('三.') !== -1) {
         noteClass = isMobile ? classes.noteRightMobile : classes.noteRight;
       }
       else if (notenum.indexOf('III.') !== -1 || notenum.indexOf('IV.') !== -1 || notenum.indexOf('VI.') !== -1) {
         noteClass = isMobile ? classes.noteRightMediumMobile : classes.noteRightMedium;
       }
       else if (notenum.indexOf('II.') !== -1 || notenum.indexOf('V.') !== -1 || notenum.indexOf('A.') !== -1 || notenum.indexOf('B.') !== -1 || notenum.indexOf('C.') !== -1 || notenum.indexOf('D.') !== -1 || notenum.indexOf('E.') !== -1 || notenum.indexOf('G.') !== -1 || notenum.indexOf('H.') !== -1) {
         noteClass = isMobile ? classes.noteRightLetterMobile : classes.noteRightLetter;
       }
       else if (notenum.indexOf('I.') !== -1) {
         noteClass = isMobile ? classes.noteRightMoreMobile : classes.noteRightMore;
       }
       else if (notenum.indexOf('F.') !== -1) {
         noteClass = isMobile ? classes.noteRightMediumMoreMobile : classes.noteRightMediumMore;
       }
       else {
         noteClass = isMobile ? classes.noteRightMostMobile : classes.noteRightMost;
       }
        let noteText;
        if (noteobj.text.substring(0, notenumend).indexOf('.') !== -1) {
            noteText = noteobj.text.substring(notenumend);
        } else {
            noteText = noteobj.text;
        }

        return (
          <Fragment>
            {notenum}<span className={noteClass} dangerouslySetInnerHTML={{__html: noteText}}/>
          </Fragment>
        );
    } else {

      return (
          <span dangerouslySetInnerHTML={{__html: noteobj.text}}/>
      );
    }
  }
  formatSubNote = (noteobj) => {

    const notenumend = noteobj.text.substring(0, 4).indexOf('.') + 1;
    const notenum = noteobj.text.substring(0, notenumend).indexOf('.') !== -1  ? noteobj.text.substring(0, notenumend).replace(' ', '') : '';

    let noteStyle = typeof noteobj['vspace'][0] !== 'undefined' ? noteobj['vspace'][0] : {};
    let noteText;

    //console.log(noteStyle);

    if (noteobj.text.substring(0, notenumend).indexOf('.') !== -1) {
      noteText = noteobj.text.substring(notenumend);
    } else {
      noteText = noteobj.text;
    }
    if (isMobile) {
      return (
          <Fragment>
            {notenum}&nbsp;&nbsp;&nbsp;<span
              dangerouslySetInnerHTML={{__html: noteText}}/>
          </Fragment>
      );
    } else {
      return (
          <Fragment>
            {notenum}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              dangerouslySetInnerHTML={{__html: noteText}}/>
          </Fragment>
      );
    }

  }
  getNoteStyle = (noteobj, currentClass) => {
    const { classes } = this.props;

    let noteClass = currentClass;

    if (typeof noteobj.vspace !== 'undefined' && noteobj.vspace.length) {
      noteClass += ' ' + classes[noteobj.vspace[0]];
    }

    return noteClass;
  }
  getNotesForVerse = (versenum, verseHighlights) => {
    const {contents} = this.props;
    const bookabbrv = (window.location.href.split('/'))[3];

    //@TODO find a way to get this from config file
    const langcode = 'cn_cob';
    const bookidx = contents[langcode].findIndex(x => x.abbrev === bookabbrv);
    const chapter = this.props.chapter;
    const foundnotes = [];
    const notes = contents[langcode][bookidx].notes;

    for (var chapt in notes) {
      if (notes.hasOwnProperty(chapt) && String(chapter) === String(chapt)) {
        for (let i = 0; i <= notes[chapt].length; i++) {
          if (typeof notes[chapt][i] !== 'undefined' && String(notes[chapt][i].verse) === String(versenum)) {
            notes[chapt][i].notes.map((note, k) => {
              const noteobj = {level: notes[chapt][i].level[k], text: note, vspace: notes[chapt][i].vspace};
              foundnotes.push(noteobj);
            });
          }
        }
      }
    }

    let notesarr = [];
    if (foundnotes.length) {
        notesarr = foundnotes;
    }

    return notesarr;
  }

  render() {
    const {classes, screenwidth} = this.props;
    const {activated} = this.props.appState;
    const {checking_activation} = this.props.appState;
    /*const keys = storage.getActivationKeys();
    const activated=this[keys[1]][keys[0]];*/

    if (this.props.chapter === 'outline' || this.props.chapter === 'chart') {

      const filecode = this.props.book + '/' + this.props.chapter;

      return (<PdfViewer filecode={filecode}/>);

    } else {

      if (!valueToObj.hasOwnProperty(this.props.book)) {
        console.log('failed');
        return <Redirect to="/"/>;
      }

      const chapters = {}; /*const { ko_abbr } = valueToObj[this.props.book];*/
      for (const [lang, books] of Object.entries(this.context)) {
        for (const book of books) {
          if (!activated && book.abbrev === 'gn') {
            chapters[lang] = book.chapters;
          } else if (book.abbrev === this.props.book) {
            chapters[lang] = book.chapters;
          }
        }
      }

      const chapterCount = Math.max.apply(null, Object.values(chapters).map(e => e.length));

      if (!(1 <= this.props.chapter && this.props.chapter <= chapterCount)) {
        console.log('failed 2');
        return <Redirect to="/"/>;
      }

      const getLength = arr => (arr && arr.length) || 0;
      const verseCount = Math.max.apply(null, Object.values(chapters).map(e => getLength(e[this.props.chapter - 1])));

      const verse = (this.props.verse && Number(this.props.verse)) || 1;
      if (!(1 <= verse && verse <= verseCount)) {
        console.log('failed 3');
        return <Redirect to="/"/>;
      }

      const verses = [];
      for (let i = 0; i < verseCount; i++) {
        const v = {};
        for (const [lang, chapter] of Object.entries(chapters)) {
          if (this.props.chapter <= chapter.length)
            v[lang] = chapter[this.props.chapter - 1][i];
        }
        verses.push(v);
      }

      const verseCtnrPadding = /*screenwidth < 960 ? '100px' :*/ '60px';
      const selectGridClass = screenwidth > 959 ? '' : 'mobile-book-chapter-grid';

      return (
          <Fragment>
            <Grid container spacing={16} className={classes.root}
                  style={{position: 'fixed', background: '#fff', paddingTop: '5px'}}>
              {this.props.chapter < chapterCount &&
                  <Link to={`/${this.props.book}/${parseInt(this.props.chapter) + 1}`} onClick={this.handleClick}>
                    <Button variant="fab" color="primary" aria-label="Add" className={classes.button}
                            disabled={!activated}>
                      <ForwardIcon className={classes.buttonIcon}/>
                    </Button>
                  </Link>}
              <Grid item xs={12} md={4} lg={3} className={selectGridClass}>
                <IntegrationReactSelect
                    suggestions={books.map(e => ({label: `${e.cn} (${e.en})`, value: e.key}))}
                    value={{
                      label: activated || checking_activation ? `${valueToObj[this.props.book].cn} (${valueToObj[this.props.book].en})` : '創世記 (Genesis)',
                      value: activated || checking_activation ? this.props.book : '創世記 (Genesis)'
                    }}
                    onChange={this.onBookChange}
                    className="book-chapter-select"
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3} className={selectGridClass}>
                <IntegrationReactSelect
                    suggestions={Array(chapterCount).fill().map((e, i) => ({value: `${i + 1}`, label: `${i + 1}章`}))}
                    value={{
                      value: activated || checking_activation ? this.props.chapter.toString() : '1',
                      label: activated || checking_activation ? `${this.props.chapter}章` : '1章'
                    }}
                    onChange={this.onChapterChange}
                    className="book-chapter-select"
                />
              </Grid>
            </Grid>
            <Grid container spacing={16} className={classes.root} style={{paddingTop: verseCtnrPadding}}>
              <Snackbar
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={this.state.snackbarOpen}
                  autoHideDuration={3000}
                  onClose={this.handleClose}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">Copied</span>}
                  action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.handleClose}
                    >
                      <CloseIcon/>
                    </IconButton>,
                  ]}
              />
              <Grid item xs={12}>
                {this.generateVerses(verses)}
              </Grid>
            </Grid>
          </Fragment>
      );
    }
  }
}

Bible.propTypes = {
  classes: PropTypes.object.isRequired,
  book: PropTypes.string.isRequired,
  chapter: PropTypes.string.isRequired,
  verse: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    appState: state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({

    }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Bible));
